import React from "react";
import Transition from "../components/transition";

const Layout = ({ children, location }: { children: any; location: any }) => (
  <main className="main-content">
    <Transition location={location}>{children}</Transition>
  </main>
);

export default Layout;
