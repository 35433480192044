exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-mii-tsx": () => import("./../../../src/pages/about-mii.tsx" /* webpackChunkName: "component---src-pages-about-mii-tsx" */),
  "component---src-pages-design-engineering-projects-tsx": () => import("./../../../src/pages/design-engineering-projects.tsx" /* webpackChunkName: "component---src-pages-design-engineering-projects-tsx" */),
  "component---src-pages-desk-tsx": () => import("./../../../src/pages/desk.tsx" /* webpackChunkName: "component---src-pages-desk-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sketches-tsx": () => import("./../../../src/pages/sketches.tsx" /* webpackChunkName: "component---src-pages-sketches-tsx" */),
  "component---src-pages-solar-potential-demo-tsx": () => import("./../../../src/pages/solar-potential-demo.tsx" /* webpackChunkName: "component---src-pages-solar-potential-demo-tsx" */),
  "component---src-pages-solar-potential-tsx": () => import("./../../../src/pages/solar-potential.tsx" /* webpackChunkName: "component---src-pages-solar-potential-tsx" */),
  "component---src-pages-wheatear-tsx": () => import("./../../../src/pages/wheatear.tsx" /* webpackChunkName: "component---src-pages-wheatear-tsx" */)
}

